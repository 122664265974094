.playerInfo {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 2.5rem;
  left: 2.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-bottom: 0.8rem;
  padding-top: 0.6rem;
  border-radius: 10px;
}