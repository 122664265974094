body {
  background: #2d333f;
}

#gameInfo {
  position: absolute;
  padding-top: 800px;
  z-index: -1;
}

#game {
  position: absolute;
}

#lobbyInfo {
  display: none;
}

#lobbySettings {
  display: none;
}

#nextSettings {
  display: none;
}

#center {
  max-width: 1000px;
  margin: auto;
}