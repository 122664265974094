.menuOverlay {
  position: absolute;
  width: 1000px;
  height: 800px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inGameOverlay {
  position: absolute;
  width: 1000px;
  height: 800px;
  z-index: 100;
  cursor: crosshair;
}