.mainMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5%;
}
.socketIdFlex {
  position: absolute;
  bottom: 1.3rem;
  right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.backButtonFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lobbyMenuNext {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 42px;
}
.optionsField {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:text;
	color:#666666;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
  width: 248px;
  height: 25px;
  margin-top: 40px;
  margin-bottom: 20px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}

.mapSelectLobbyButton {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:20px;
	font-weight:bold;
  width: 250px;
  height: 50px;
  margin-bottom: 20px;
	text-decoration:none;
  text-align: center;
	text-shadow:0px 1px 0px #ffffff;
}
.lobbyMenuButtonNext {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:20px;
	font-weight:bold;
  text-align: center;
  width: 102px;
  height: 50px;
  margin-bottom: 20px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.mapSelectLobbyButton:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.mapSelectLobbyButton:active {
	position:relative;
	top:1px;
}
.backButton {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:30px;
	font-weight:bold;
  width: 250px;
  height: 50px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.backButton:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.backButton:active {
	position:relative;
	top:1px;
}
.lobbyMenuButtonNext:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.lobbyMenuButtonNext:active {
	position:relative;
	top:1px;
}
.menuText {
  text-shadow:0px 1px 0px #ffffff;
  color: #ffffff;
  text-align: center;
	font-family:Arial;
	font-size:20px;
	font-weight:bold;
}
.copyButton {
	font-size: 24px;
  width: 45px;
  height: 38px;
  cursor: pointer;
}

.menuButton {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:30px;
	font-weight:bold;
  width: 250px;
  height: 50px;
  margin-bottom: 20px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.menuButton:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.menuButton:active {
	position:relative;
	top:1px;
}
.copyButton:active {
	position:relative;
	top:1px;
}
